@tailwind base;
@tailwind components;

@import "fonts.css";

@layer base {
  body {
    @apply font-sans-serif text-primary;
  }
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

h1,
h2,
h3 {
  @apply font-semibold;
}

@tailwind utilities;
